// ** Zona para inserir a descrição do vídeo
import React, { useState } from "react";
//import Card from "@mui/material/Card";
//import CardContent from "@mui/material/CardContent";
import EyeOutline from "mdi-material-ui/EyeOutline";
import OpenInNew from "mdi-material-ui/OpenInNew";
import CloudDownloadOutline from "mdi-material-ui/CloudDownloadOutline";
import MuiLink from "@mui/material/Link";
// import PDFDrawer from "./PDFDrawer";
// ** MUI Imports
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// ** Custom Components
import CustomChip from "src/@core/components/mui/chip";
import { Button } from "@mui/material";

const urlElipsis = (url, isMobile) => {
  try {
    const maxLen = isMobile ? 25 : 50;
    if (url.length > maxLen) {
      return "..." + url.substr(url.length - maxLen);
    }
    return url;
  } catch (error) {
    return url;
  }
};

function Content({ content, isMobile }) {
  // const [pdfDrawer, setPdfDrawer] = useState({ visible: false, data: null });
  if (content === undefined || content === null || content === "" || content?.length === 0)
    return (
      <Box sx={{ p: 10 }}>
        <Typography variant="body2">
          <em>Não existe conteúdo...</em>
        </Typography>
      </Box>
    );

  return (
    <>
      <Box sx={{ p: 4 }}>
        <>
          {content.map((item, index) => {
            const fileExtension = item?.filename?.split(".").pop();
            return (
              <Box
                key={item.key}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <CustomChip
                      size="small"
                      label={fileExtension.toUpperCase() || "Desconhecido"}
                      color="info"
                      sx={{ height: 20, fontSize: "0.75rem", fontWeight: 500, mr: 2 }}
                    />
                    <MuiLink href={item.url} target="_blank">
                      <Typography variant="body2" sx={{ mb: 0.5, fontWeight: 600, color: "text.primary" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {urlElipsis(item.name, isMobile)} <OpenInNew fontSize="small" sx={{ ml: 2 }} />
                        </div>
                      </Typography>
                    </MuiLink>
                  </div>

                  {isMobile !== true && (
                    <MuiLink href={item.url}>
                      <Button color="primary" variant="contained">
                        Download <CloudDownloadOutline fontSize="small" sx={{ ml: 2 }} />
                      </Button>
                    </MuiLink>
                  )}
                  {isMobile === true && (
                    <MuiLink href={item.url}>
                      <Button color="primary" variant="contained" sx={{ ml: 2 }}>
                        <CloudDownloadOutline fontSize="small" />
                      </Button>
                    </MuiLink>
                  )}
                </Box>
              </Box>
            );
          })}
        </>
      </Box>
      {/* <PDFDrawer visible={pdfDrawer.visible} data={pdfDrawer.data} close={() => setPdfDrawer({ visible: false })} /> */}
    </>
  );
}

export default Content;
