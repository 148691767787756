// ** A Tab para mudar entre descrição e comentários

// ** React Imports
import React from "react";
import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import moment from "moment/moment";
import { locale } from "src/lang/locale/moment_pt_PT";

import Icon from "src/@core/components/icon";
// ** Component Import
import Description from "./Description";
import LessonTitle from "./LessonTitle";
import Content from "./Content";
import { Button } from "@mui/material";
// ** Icon Imports
const isMobile = window.innerWidth <= 760;
// Styled TabList component
// const TabList = styled(MuiTabList)(({ theme }) => ({
//   "& .MuiTabs-indicator": {
//     display: "none",
//   },
//   "& .Mui-selected": {
//     backgroundColor: theme.palette.primary.main,
//     color: `${theme.palette.common.white} !important`,
//   },
//   "& .MuiTab-root": {
//     minHeight: 38,
//     minWidth: isMobile ? 0 : 250,
//     borderRadius: 8,
//     paddingTop: theme.spacing(2),
//     paddingBottom: theme.spacing(2),
//   },
// }));
moment.locale("pt", locale);
//import CommentsInsert from "./Comments/CommentsInsert";

const _isChapterAvailable = (chapter, courseAddedAt) => {
  //console.log("_isChapterAvailable", chapter, courseAddedAt);
  if (chapter.status_code === 0) return false;
  if (chapter.status_code === 1) return true;

  // Dripping
  if (chapter.status_code === 2 && chapter.drip_days !== undefined) {
    const avaliableDate = moment.utc(courseAddedAt).add(chapter.drip_days, "days");

    if (moment.utc().isAfter(avaliableDate)) return true;
  }

  // Avaliable On
  if (chapter.status_code === 3 && chapter.avaliable_on !== undefined) {
    if (moment.utc().isAfter(chapter.avaliable_on)) return true;
  }

  return false;
};

function Details({ details, tab, setTab, activeChapter, courseAddedAt }) {
  // ** State

  const asDetails = details?.content !== undefined && details?.content !== null && details?.content !== "";
  const asContent =
    details?.files_content !== undefined &&
    details?.files_content !== null &&
    details?.files_content !== "" &&
    details?.files_content?.length > 0;
  // If there is no content, set the tab to 2
  if (asDetails === false && asContent === true) {
    setTab("2");
  }
  if (asDetails === true && asContent === false && tab === "2") {
    setTab("1");
  }

  const handleChange = (event, newValue) => {
    setTab(newValue);
  }; //muda o valor da tab

  return (
    <>
      {_isChapterAvailable(activeChapter, courseAddedAt) && (
        <Card style={{ borderRadius: 0, width: "100%" }}>
          <LessonTitle chapterName={activeChapter?.title} lessonName={details?.title} />
          <div style={{ marginTop: 10 }}>
            {asDetails === true && (
              <Button
                style={{ marginLeft: 10, marginRight: 10 }}
                variant={tab === "1" ? "contained" : "outlined"}
                size="large"
                onClick={() => setTab("1")}
              >
                <Icon icon="mdi:list-box-outline" fontSize="1rem" /> <span style={{ marginLeft: 7 }}>Detalhes</span>
              </Button>
            )}
            {asContent === true && (
              <Button
                style={{ marginLeft: 10, marginRight: 10 }}
                variant={tab === "2" ? "contained" : "outlined"}
                size="large"
                onClick={() => setTab("2")}
              >
                <Icon icon="mdi:file-multiple" fontSize="1rem" /> <span style={{ marginLeft: 7 }}>Materiais</span>
              </Button>
            )}
          </div>
          <div>
            {tab === "1" && asDetails === true && <Description content={details?.content} />}
            {tab === "2" && asContent === true && <Content content={details?.files_content} isMobile={isMobile} />}
          </div>
          {/* <TabContext value={tab}>
            <TabList onChange={handleChange} variant="fullWidth" style={{ padding: 10 }}>
              <Tab value="1" label="Detalhes" icon={<Icon icon="mdi:list-box-outline" />} />
              <Tab value="2" label="Materiais" icon={<Icon icon="mdi:file-multiple" />} />
            </TabList>

            <TabPanel value="1">
              <Description content={details?.content} />
            </TabPanel>
            <TabPanel value="2">
              <Box>
                <Content content={details?.files_content} />
              </Box>
            </TabPanel>
          </TabContext> */}
        </Card>
      )}
    </>
  );
}

export default Details;
